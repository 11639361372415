export const addIdGenerator = (idGenerators, idGenerator, areaName) => {
  idGenerators.push({
    name: idGenerator,
    format: `${areaName}-%04d`,
    discType: 'ONE_ID_GENERATOR',
    startId: 1,
    increment: 1,
  });
};

export const addMultiIdGenerator = (idGenerators, idGenerator, areaName, formatPrefix = '') => {
  idGenerators.push({
    name: idGenerator,
    format: `${areaName}-${formatPrefix}B%02dC%02dS%02dD%02d`,
    discType: 'MULTI_ID_GENERATOR',
    startFirstId: 1,
    incrementFirstId: 1,
    additionalIncrementers: [
      {
          sequence: 0,
          startId: 1,
          increment: 1,
          numIds: 1
      },
      {
          sequence: 1,
          startId: 1,
          increment: 1,
          numIds: 1
      },
      {
          sequence: 2,
          startId: 1,
          increment: 1,
          numIds: 1
      },
      {
          sequence: 3,
          startId: 1,
          increment: 1,
          numIds: 1
      }]
  });
};

export const addCheckPointIdGenerators = (
  checkPointIdGenerators,
  checkPointIdGenerator,
  highwayName,
) => {
  checkPointIdGenerators.push({
    name: checkPointIdGenerator,
    format: `${highwayName}-%04d`,
    discType: 'ONE_ID_GENERATOR',
    startId: 1,
    increment: 1,
  });
};
