import { mapCheck } from './utils';

export const addAreaMappingFromHighwayToOtherShapes = (
  areaMappings,
  highwayId,
  mapAllNearestCps,
  mapCpsAtCrossing,
  mapCpsToEps,
  mapTwoNearestCps,
  mapHighwayToServiceRoadCps,
  mapCpsToNarrowAisleEps,
) => {
  const hasAnyMap =
    !!mapCheck(mapAllNearestCps) ||
    !!mapCheck(mapCpsAtCrossing) ||
    !!mapCheck(mapCpsToEps) ||
    !!mapCheck(mapHighwayToServiceRoadCps) ||
    !!mapCheck(mapTwoNearestCps) ||
    !!mapCheck(mapCpsToNarrowAisleEps)
  if (!hasAnyMap) return;

  areaMappings.push({
    sequence: areaMappings.length + 1,
    mapTwins: null,
    mapNeighbors: null,
    areaReference: {
      name: highwayId,
    },
    mapAllNearestCps: mapCheck(mapAllNearestCps),
    mapCpsAtCrossing: mapCheck(mapCpsAtCrossing),
    mapCpsToEps: mapCheck(mapCpsToEps),
    mapCpsToEpsAtCrossing: null,
    mapEpNeighbors: null,
    mapEpsMultiDeep: null,
    mapCpsToNarrowAisleEps: mapCheck(mapCpsToNarrowAisleEps),
    mapEpsToGetawayCps: null,
    mapEpsToLocalCps: null,
    mapHighwayToServiceRoadCps: mapCheck(mapHighwayToServiceRoadCps),
    mapTwoNearestCps: mapCheck(mapTwoNearestCps),
  });
};
