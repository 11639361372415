import { Vector2 } from 'three';
import { v4 as uuid } from 'uuid';

import {
  RACK_ALIGNMENT_OVERRIDE,
  RACK_DISTRIBUTION_OVERRIDE,
  RACK_MIN_GAP_OVERRIDE,
} from '@/modules/common/constants/storage';
import { AreaDirection } from '@/modules/common/types/shapes';
import { StorageType } from '@/modules/common/types/storage';
import { LoadHandlingType, StationaryTypeEnum, TwinConfiguration, VehiclePosition } from './types';
import {
  alignmentAdjustment,
  alignmentToFPAlignment,
  blockStorageLength,
  blockStorageWidth,
  getRackNameFromShapeId,
  loadCarrierTypesToFPLoadTypeReferences,
  sideLoadingDelta,
  storageAlignment,
} from './utils';

export const addEndPointGenerationSettings = (
  vehicleSpec,
  id,
  shape,
  epIdGenerator,
  lpIdGenerator,
  secondLpIdGenerator,
  areaType,
  discType,
  vehicleSize,
  supportedLoadCarrierTypes,
  loadPositionWidth,
  loadPositionLength,
  loadPositionAngle,
  direction,
  endPointAngle,
) => {
  const alignment =
    shape.parameters.storageType === StorageType.BLOCKSTACKING
      ? storageAlignment(direction)
      : shape.parameters.alignment;

  const distributionStrategy =
    shape.parameters.distribution !== undefined
      ? shape.parameters.distribution
      : 'EXTRA_SPACE_OVER_MARGIN';

  let restSettings = {};

  if (areaType === StationaryTypeEnum.CHARGING || areaType === StationaryTypeEnum.PARKING) {
    restSettings = {
      type: areaType,
      laneLength: vehicleSize.length,
      laneWidth: vehicleSize.width,
    };
  } else if (shape.parameters.storageType === StorageType.BLOCKSTACKING) {
    restSettings = {
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGenerator,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(supportedLoadCarrierTypes),
      forceFallBack: null,
      loadPositionWidth: blockStorageWidth(
        shape.parameters.loadCarrierOrientation,
        loadPositionWidth,
        loadPositionLength,
      ),
      loadPositionLength: blockStorageLength(
        shape.properties,
        direction,
        shape.parameters.loadCarrierOrientation,
        vehicleSize.length,
        loadPositionWidth,
        loadPositionLength,
      ),
      loadPositionAngle: 0,
    };
  } else if (shape.parameters.storageType === StorageType.SIDELOADING) {
    const centerDelta = sideLoadingDelta(
      direction,
      new Vector2(
        shape.parameters.storageProperty.sideLoadingDeltaX,
        shape.parameters.storageProperty.sideLoadingDeltaY,
      ),
    );

    restSettings = {
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGenerator,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(supportedLoadCarrierTypes),
      forceFallBack: null,
      loadPositionWidth,
      loadPositionLength,
      loadPositionAngle,
      centerDeltaX: centerDelta.x,
      centerDeltaY: centerDelta.y,
    };
  } else if (shape.parameters.storageType === StorageType.RACK || shape.parameters.storageType === StorageType.TWOSIDEDRACK) {
    const otherSide = direction === AreaDirection.DOWN || direction === AreaDirection.RIGHT;
    let twinConfig = shape.parameters.storageType === StorageType.TWOSIDEDRACK 
    ? TwinConfiguration.TWO_WAY 
    : otherSide
    ? TwinConfiguration.ONE_WAY_KEEP_SECOND
    : TwinConfiguration.ONE_WAY_KEEP_FIRST;

    restSettings = {
      rackSpecId: getRackNameFromShapeId(shape.id),
      name: `${id}-epGenSettings`,
      aisleWidth: shape.parameters.storageProperty.aisleWidth,
      laneLength: 500,
      vehiclePosition: VehiclePosition.PERPENDICULAR,
      offsetDriving: 0,
      otherSide,
      twinConfig,
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGenerator,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(supportedLoadCarrierTypes),
      forceFallBack: null,
      loadPositionWidth,
      loadPositionLength,
      loadPositionAngle,
      minGap: RACK_MIN_GAP_OVERRIDE,
      distributionStrategy: RACK_DISTRIBUTION_OVERRIDE,
      alignment: alignmentToFPAlignment(RACK_ALIGNMENT_OVERRIDE),
      secondLoadPositionIdGeneratorBk: secondLpIdGenerator,
      laneDirectionInAisle: shape.parameters.storageProperty.laneDirectionInAisle,
    };
  } else {
    restSettings = {
      loadHandlingType: areaType,
      loadPositionIdGeneratorBk: lpIdGenerator,
      loadPositionHeight: null,
      loadTypeReferences: loadCarrierTypesToFPLoadTypeReferences(supportedLoadCarrierTypes),
      forceFallBack: null,
      loadPositionWidth,
      loadPositionLength,
      loadPositionAngle,
      loadPositionZ: shape.parameters.loadElevation === 0 ? null : shape.parameters.loadElevation,
    };
  }

  if (areaType === LoadHandlingType.PROCESS) {
    restSettings = { ...restSettings, processingCapacity: 1 };
  }

  vehicleSpec.endPointGenerationSettings.push({
    properties: shape.properties,
    data: {
      name: uuid(),
      epIdGeneratorBk: epIdGenerator,
      commsIdGeneratorBk: 'PointIdGen',
      idShortenerBk: 'IdShort',
      angle: endPointAngle,
      margin: shape.parameters.margin,
      minGap: shape.parameters.gap,
      alignment: alignmentToFPAlignment(
        alignmentAdjustment(shape.properties.width, shape.properties.height, direction, alignment),
      ),
      distributionStrategy,
      discType,
      areaReference: {
        name: id,
      },
      epCutOutReferences: [],
      ...restSettings,
    },
  });
};
